import { BusinessFactCheck, BusinessFactCheckEvidence } from '~/models/types';

export const businessFactCheckEvidence: BusinessFactCheckEvidence = {
  businessFactCheckEvidenceId: 0,
  url: 'https://placehold.jp/800x1200.png',
  status: 'prepared',
  type: {
    masterBusinessFactCheckEvidenceTypeId: 1,
    label: '開業届',
    others: false,
  },
  rejectReasons: [
    {
      businessFactCheckEvidenceRejectReasonId: 1,
      type: {
        masterBusinessFactCheckEvidenceRejectReasonTypeId: 1,
        label: '書類の代表者氏名が読み取れない',
        others: false,
        requiredCustomerSupportContact: false,
      },
      rejectReasonText: null,
    },
    {
      businessFactCheckEvidenceRejectReasonId: 2,
      type: {
        masterBusinessFactCheckEvidenceRejectReasonTypeId: 2,
        label: '書類の住所が読み取れない',
        others: false,
        requiredCustomerSupportContact: true,
      },
      rejectReasonText: null,
    },
    {
      businessFactCheckEvidenceRejectReasonId: 3,
      type: {
        masterBusinessFactCheckEvidenceRejectReasonTypeId: 3,
        label: 'その他',
        others: true,
        requiredCustomerSupportContact: false,
      },
      rejectReasonText: '画像が粗く認識できない',
    },
  ],
};

const defaultValue: BusinessFactCheck = {
  businessFactCheckId: 1,
  status: 'rejected',
  evidence: businessFactCheckEvidence,
};

export const businessFactCheck = (_pattern: number): BusinessFactCheck => defaultValue;

businessFactCheck.default = defaultValue;
